<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ domain.name }}</h1>
                <p class="text-caption text-center">Domain overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="domain">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="teal" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Domain
                        </v-toolbar-title>
                        <!-- <template v-if="isDraft">
                            <v-spacer/>
                            <v-btn color="white green--text" @click="publish">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']"/>
                                Publish
                            </v-btn>
                        </template> -->
                        <!-- <v-progress-linear
                            :active="emailDispatchStatus && emailDispatchStatus.status === 'started'"
                            :value="emailDispatchProgress"
                            absolute
                            bottom
                            color="green"
                        ></v-progress-linear> -->
                        </v-toolbar>
                        <v-card-text>

            <!-- <v-row justify="center" class="py-5 px-10" v-if="domain"> -->
                <!-- <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0"> -->
                    <p class="text-overline mb-0">Name</p>
                    <p class="mb-0 pb-0">
                        {{ domain.name }}
                        <!-- <v-btn icon color="green" @click="editDomainName">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn> -->
                    </p>

                    <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        {{ domain.status }}
                        <!-- <v-btn icon color="green" @click="editDomainComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn> -->
                    </p>
                    <p v-if="domain.status !== 'verified'">
                        <router-link :to="{ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domainId: domain.id } }">Verify this domain</router-link>
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ domain.comment }}
                        <v-btn icon color="green" @click="editDomainComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p> -->

                    <p class="text-overline mb-0 mt-8">Domain ID</p>
                    <p class="mb-0 pb-0">
                        {{ domain.id }}
                    </p>

                    <!-- TODO: the affected X list should depend on the domain type... instead of hard-coding email contacts -->
                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This domain is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            < ! - - TODO: show the affectedEmailContactList - - >
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This domain is not currently applied to any email contacts.
                        </span>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, domainId: this.$route.params.domainId } }">Access control</router-link>
                    </p> -->

                    <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                        <v-btn text color="green" class="px-0" @click="checkDomainStatus">Check domain</v-btn>
                    </p>
                    <p class="mb-0 pb-0" v-if="domain.status === 'verified' && errorReservation === true">
                        <v-btn text color="green" class="px-0" @click="fixDomainStatus">Fix domain</v-btn>
                    </p>
                    <p class="mb-0 pb-0">
                        <v-btn text color="green" class="px-0" @click="editDomainStatus('verified')" v-if="domain.status !== 'verified'">Verify domain</v-btn>
                        <v-btn text color="green" class="px-0" @click="editDomainStatus('pending')" v-if="domain.status === 'verified'">Suspend domain</v-btn>
                    </p>
                    </template>
                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Domain Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.form, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editDomainNameDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the domain label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The domain label informs users about the content of communications they will receive if they subscribe to it.
                            You can change it at any time, but the new meaning should be similar to the old one. It IS shown to users.
                        </p>

                        <v-text-field
                            ref="domainNameInput"
                            v-model="editableDomainName"
                            label="Name"
                            :rules="newDomainNameRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditDomainName" :disabled="!isEditDomainNameComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editDomainNameDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editDomainCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the domain comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The domain comment is a place where you can add more information for your own reference about how you use the domain.
                            You can change it at any time. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editableDomainComment" label="Domain comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditDomainComment" :disabled="!isEditDomainCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editDomainCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import DomainList from '@/components/account-dashboard/DomainList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // DomainList,
    },
    data: () => ({
        affectedEmailContactList: [],

        error: null,
        errorReservation: null,
        errorConflict: null,

        account: null,
        domain: null,
        invite: null,
        // edit form title dialog
        editDomainNameDialog: false,
        editDomainCommentDialog: false,
        editableDomainComment: null,

        editableDomainName: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.form !== null;
        },
        isEditDomainNameComplete() {
            return typeof this.editableDomainName === 'string' && this.editableDomainName.trim().length > 0;
        },
        isEditDomainCommentComplete() {
            return typeof this.editableDomainComment === 'string' && this.editableDomainComment.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ id: this.$route.params.domainId });
                console.log(`loadDomain: response ${JSON.stringify(response)}`);
                if (response) {
                    this.domain = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.domain.comment ??= '';
                    // this.domain.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load domain');
                }
            } catch (err) {
                console.error('failed to load domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
        async save(domainAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditDomain: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).domain.edit({ id: this.$route.params.domainId }, domainAttr);
                console.log(`saveEditDomain: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit domain' });
                return false;
            } catch (err) {
                console.error('failed to edit domain', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit domain' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditDomain: false });
            }
        },
        editDomainName() {
            this.editDomainNameDialog = true;
            this.editableDomainName = this.domain.name;
        },
        editDomainComment() {
            this.editDomainCommentDialog = true;
            this.editableDomainComment = this.domain.comment;
        },
        async saveEditDomainName() {
            const isEdited = await this.save({ name: this.editableDomainName });
            this.editDomainNameDialog = false;
            if (isEdited) {
                this.$set(this.domain, 'name', this.editableDomainName);
            }
        },
        async saveEditDomainComment() {
            const isEdited = await this.save({ comment: this.editableDomainComment });
            this.editDomainCommentDialog = false;
            if (isEdited) {
                this.$set(this.domain, 'comment', this.editableDomainComment);
            }
        },
        async editDomainStatus(newValue) {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { editDomainStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).domainStatus.edit({ domain_id: this.$route.params.domainId }, { status: newValue });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.domain, 'status', newValue);
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Domain status updated', message: `New status: ${newValue}` });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update domain status' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update domain status' });
            } finally {
                this.$store.commit('loading', { editDomainStatus: false });
            }
        },
        async checkDomainStatus() {
            // NOTE: account owner may check status
            try {
                this.$store.commit('loading', { checkDomainStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.check({ id: this.$route.params.domainId }, { item: 'status' });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response.status === 'verified' && response.reservation) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Domain is verified' });
                    this.errorReservation = false;
                    this.errorConflict = false;
                } else if (response.error === 'reservation') {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Domain not assigned to this account' });
                    this.errorReservation = true;
                } else if (response.error === 'conflict') {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Domain assigned to another account' });
                    this.errorConflict = true;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check domain status' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check domain status' });
            } finally {
                this.$store.commit('loading', { checkDomainStatus: false });
            }
        },
        async fixDomainStatus() {
            // NOTE: only server admin may use the 'fix' parameter; server does access control check for this
            try {
                this.$store.commit('loading', { fixDomainStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.check({ id: this.$route.params.domainId }, { item: 'status', fix: true });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response.status === 'verified' && response.reservation) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Domain is verified' });
                    this.errorReservation = false;
                    this.errorConflict = false;
                } else if (response.error === 'reservation') {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Domain not assigned to this account' });
                    this.errorReservation = true;
                } else if (response.error === 'conflict') {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Domain assigned to another account' });
                    this.errorConflict = true;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check domain status' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check domain status' });
            } finally {
                this.$store.commit('loading', { fixDomainStatus: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDomain();
    },
};
</script>
